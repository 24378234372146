/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import MotionElement from "../../../components/MotionElement/MotionElement";
import DateComponent from "./DateComponent";
import "../../HomePage/HomePage.css";
import Common from "./Common";
import Private from "./Private";
import { Typography, IconButton } from "@mui/material";
import { styles } from "../Booking.styles";
import labels from "../Booking.labels.json";
import { WindowDimensions } from "../../../App";
import {
  useStoreState,
  useStoreActions,
  IBooking,
} from "../../../custom/store/store";
import { isMobile } from "react-device-detect";
import request from "../../../custom/request";
import { url } from "../../../custom/constants";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { Wave } from "../../../components/Wave/Wave";
import Snackbar from "../../../components/Snackbar/Snackbar";
import DialogBox from "../../../components/DialogBox/DialogBox";
import InputField from "../../../components/InputField/InputField";
import InfoIcon from "@mui/icons-material/Info";
import MultilineDescription from "../../../components/MultilineDescription/MultilineDescription";
import Pricing from "./Pricing";

interface IDateSelect {
  selected: "private" | "common" | "none";
}

const DateSelect = ({ selected }: IDateSelect) => {
  const windowDimensions = useContext(WindowDimensions);
  const selectedSlots = useStoreState((state) => state.selectedSlots);
  const bookDetails = useStoreState((state) => state.bookDetails);
  const setBookDetails = useStoreActions((actions) => actions.setBookDetails);
  const setBookPageLoad = useStoreActions((actions) => actions.setBookPageLoad);

  const setSelectedSlots = useStoreActions(
    (actions) => actions.setSelectedSlots
  );

  let dateNow = new Date();
  let navigate = useNavigate();
  const buttonControls = useAnimation();
  const textControls = useAnimation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+91");
  const [validationError, setValidationError] = useState({
    email: false,
    phone: false,
  });

  const [selectedDate, setSelectedDate] = useState("");
  const [snackText, setSnackText] = useState("");
  const [reverse, setReverse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPricing, setOpenPricing] = useState(false);
  const [bookedSlots, setBookedSlots] = useState<IBooking[]>([]);

  const showDates = () => {
    let date;
    let dateArr = [];
    for (let i = 0; i < 7; i++) {
      date = new Date();
      if (dateNow.getHours() >= 22) {
        date.setDate(date.getDate() + i + 1);
      } else {
        date.setDate(date.getDate() + i);
      }
      dateArr.push(
        <DateComponent
          key={i}
          date={date}
          index={i}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      );
    }
    return dateArr;
  };

  useEffect(() => {
    if (dateNow.getHours() >= 22) {
      setSelectedDate(
        `${dateNow.getDate() + 1}/${
          dateNow.getMonth() + 1
        }/${dateNow.getFullYear()}`
      );
    } else {
      setSelectedDate(
        `${dateNow.getDate()}/${
          dateNow.getMonth() + 1
        }/${dateNow.getFullYear()}`
      );
    }

    request(`${url}/slots`, "GET").then((data: any) => {
      setBookedSlots(data.data);
    });

    return () => {
      setBookPageLoad(false);
    };
  }, []);

  useEffect(() => {
    setBookDetails({ ...bookDetails, date: selectedDate, timeArr: [] });
    setSelectedSlots(0);
  }, [selectedDate]);

  useEffect(() => {
    textControls.start("black");
    setBookDetails({ ...bookDetails, slots: selectedSlots });
  }, [selectedSlots]);

  useEffect(() => {
    if (!openDialog) {
      setName("");
      setEmail("");
      setPhone("+91");
    }
  }, [openDialog]);

  const captureContact = () => {
    if (selectedSlots > 0) {
      setOpenDialog(true);
      return;
    }
    setOpenSnack(true);
    setSnackText(labels.snackMsg.noSlots);
    textControls.start("crimson");
    buttonControls.start("error");
    setTimeout(() => {
      buttonControls.start("initial");
    }, 200);
  };

  const checkValidity = () => {
    const phoneRegex =
      /^(?:(?:\+|0{0,2})91(\s*|[-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailCheck = emailRegex.test(email);
    const phoneCheck = phoneRegex.test(phone);

    if (!emailCheck) {
      setValidationError((p) => ({ ...p, email: true }));
      setOpenSnack(true);
      setSnackText(labels.snackMsg.invalidEmail);
      return false;
    }
    setValidationError((p) => ({ ...p, email: false }));
    if (!phoneCheck) {
      setValidationError((p) => ({ ...p, phone: true }));
      setOpenSnack(true);
      setSnackText(labels.snackMsg.invalidPhone);
      return false;
    }
    setValidationError((p) => ({ ...p, email: false }));
    return true;
  };

  const bookNow = () => {
    if (!name || !email || !phone) {
      setOpenSnack(true);
      setSnackText(labels.snackMsg.enterValue);
      return;
    }

    const validity = checkValidity();

    if (!validity) {
      return;
    }

    setOpenDialog(false);
    setLoading(true);
    request(`${url}/book`, "POST", { ...bookDetails, name, email, phone }).then(
      (data: any) => {
        if (data.msg === "failed") {
          setOpenSnack(true);
          setSnackText(labels.snackMsg.bookFailed);
          setLoading(false);
          return;
        }
        setReverse(true);
        setTimeout(() => {
          setLoading(false);
          navigate("/confirmation");
        }, 500);
      }
    );
  };

  return (
    <div
      style={{
        width: "100vw",
      }}
    >
      {openPricing && (
        <DialogBox heading="Pricing details" setOpen={setOpenPricing}>
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  ...styles.typography,
                  fontSize: "1.5rem",
                  textAlign: "left",
                  color: "#ff5d00",
                }}
              >
                {labels.private}
              </Typography>
              <MultilineDescription
                multilineText={[labels.slotsPrivate, labels.pricing.extDesc]}
                textAlign="left"
              />
              <Pricing pool="private" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  ...styles.typography,
                  fontSize: "1.5rem",
                  textAlign: "left",
                  color: "seagreen",
                }}
              >
                {labels.common}
              </Typography>
              <MultilineDescription
                multilineText={[labels.slotsCommon, labels.pricing.extDesc]}
                textAlign="left"
              />
              <Pricing pool="common" />
            </div>
          </>
        </DialogBox>
      )}
      {openDialog && (
        <DialogBox heading="Contact details" setOpen={setOpenDialog}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <InputField
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputField
              placeholder="Email"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              error={validationError.email}
            />
            <InputField
              placeholder="Phone"
              value={phone}
              maxLength={13}
              error={validationError.phone}
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  return;
                }
                const reg = /^\d+$/;
                const digitCheck = reg.test(e.target.value.substring(3));
                if (digitCheck || e.target.value === "+91") {
                  setPhone(e.target.value);
                }
              }}
            />
            <motion.button
              whileHover={{ scale: isMobile ? 0.9 : 1.1 }}
              transition={{
                duration: 1,
                type: "spring",
                bounce: 0.5,
              }}
              style={{
                ...styles.button,
                width: `${windowDimensions.width / 8}px`,
                cursor: isMobile ? "default" : "pointer",
              }}
              onClick={bookNow}
            >
              <Typography
                style={{
                  fontSize: `1.2rem`,
                  color: "#ff5d00",
                  fontFamily: "jost",
                }}
              >
                {labels.submit}
              </Typography>
            </motion.button>
          </div>
        </DialogBox>
      )}

      {openSnack && (
        <Snackbar text={snackText} close={() => setOpenSnack(false)} />
      )}
      <MotionElement
        duration={1}
        hide={{ opacity: 0 }}
        show={{ opacity: 1 }}
        // delay={0.5}
        reverse={reverse}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="horizontalScroll"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: windowDimensions.width > 900 ? "center" : "",
              overflowX: "scroll",
              paddingBottom: "5px",
              // background:'red'
            }}
          >
            {showDates()}
          </div>
          <div style={styles.slotsContainer}>
            <div style={styles.slotsDiv}>
              <div
                style={{ ...styles.slotsAvailabilityDiv, background: "teal" }}
              />
              <Typography style={styles.slotsTypography}>
                {labels.slots.booked}
              </Typography>
            </div>
            <div style={styles.slotsDiv}>
              <div
                style={{
                  ...styles.slotsAvailabilityDiv,
                  background: "lightblue",
                }}
              />
              <Typography style={styles.slotsTypography}>
                {labels.slots.available}
              </Typography>
            </div>
            <div style={styles.slotsDiv}>
              <div
                style={{
                  ...styles.slotsAvailabilityDiv,
                  background: "steelblue",
                }}
              />
              <Typography style={styles.slotsTypography}>
                {labels.slots.selected}
              </Typography>
            </div>
            <div style={styles.slotsDiv}>
              <div
                style={{
                  ...styles.slotsAvailabilityDiv,
                  background: "crimson",
                }}
              />
              <Typography style={styles.slotsTypography}>
                {labels.slots.unavailable}
              </Typography>
            </div>
          </div>
          <div style={styles.poolContainer}>
            <Common selected={selected} bookedSlots={bookedSlots} />
            <Private selected={selected} bookedSlots={bookedSlots} />
          </div>
          <MotionElement
            duration={0.8}
            hide={{ opacity: 0, scale: 0 }}
            show={{ opacity: 1, scale: 1 }}
            delay={1}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "30px" }}>
                <motion.span
                  variants={{
                    crimson: { color: "#DC143C" },
                    black: { color: "#000000" },
                  }}
                  initial="black"
                  animate={textControls}
                  transition={{
                    ease: "linear",
                    duration: 0.2,
                  }}
                  style={{
                    ...styles.typography,
                    fontSize: "1.4rem",
                  }}
                >{`${labels.slotsSelected}${selectedSlots}`}</motion.span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // background: "red",
                }}
              >
                <Typography
                  style={{
                    ...styles.typography,
                    color: "steelblue",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenPricing(true);
                  }}
                >
                  {labels.pricing.label}
                </Typography>
                <IconButton
                  aria-label="info"
                  onClick={() => {
                    setOpenPricing(true);
                  }}
                >
                  <InfoIcon sx={{ color: "steelblue", fontSize: "1.5rem" }} />
                </IconButton>
              </div>
              <motion.button
                variants={{
                  error: { x: -20 },
                  initial: { x: 0 },
                }}
                initial="initial"
                animate={buttonControls}
                whileHover={{ scale: isMobile ? 0.9 : 1.2 }}
                transition={{
                  duration: 1,
                  type: "spring",
                  bounce: 0.5,
                }}
                style={{
                  ...styles.button,
                  marginTop: "10px",
                  width: `${windowDimensions.width / 8}px`,
                  cursor: isMobile ? "default" : "pointer",
                }}
                onClick={captureContact}
              >
                {!loading ? (
                  <Typography
                    style={{
                      fontSize: `1.2rem`,
                      color: "#ff5d00",
                      fontFamily: "jost",
                    }}
                  >
                    {labels.book}
                  </Typography>
                ) : (
                  <Wave />
                )}
              </motion.button>
            </div>
          </MotionElement>
        </div>
      </MotionElement>
    </div>
  );
};
export default DateSelect;
