import MultilineDescription from "../../../components/MultilineDescription/MultilineDescription";
import labels from "../Booking.labels.json";

interface IPricing {
  pool: string;
}

const Pricing = ({ pool }: IPricing) => {
  let pricing: any[] = [];
  if (pool === "private") {
    pricing = [
      { price: "₹1200", round: "1", dur: "10 mins" },
      { price: "₹2000", round: "2", dur: "20 mins" },
      { price: "₹2400", round: "3", dur: "30 mins" },
    ];
  }
  if (pool === "common") {
    pricing = [
      { price: "₹150", round: "1", dur: "10 mins" },
      { price: "₹250", round: "2", dur: "20 mins" },
      { price: "₹300", round: "3", dur: "30 mins" },
    ];
  }

  return (
    <>
      <div
        style={{
          display: "inline-grid",
          gridTemplateColumns: "auto auto auto",
          marginTop: "10px",
        }}
      >
        <MultilineDescription
          multilineText={[
            labels.pricing.price,
            labels.pricing.round,
            labels.pricing.time,
          ]}
          textAlign="left"
        />
        {pricing.map((obj) => {
          return (
            <MultilineDescription
              multilineText={[obj.price, obj.round, obj.dur]}
              textAlign="left"
            />
          );
        })}
      </div>
    </>
  );
};
export default Pricing;
